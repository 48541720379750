import { Visibility, VisibilityOff } from "@mui/icons-material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid-pro";
import axios from "axios";
import { getIn, useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { getStation, upsertStation } from "src/api/stations";
import { StationTemplateResponse } from "src/api/stations/interface";
import ElementBox from "src/components/ElementBox";
import UserContext from "src/components/UserContext";
import EntityEditForm from "src/components/forms/EntityEditForm";
import Page from "src/components/layout/Page";
import { notifyAxiosError, notifyError } from "src/components/notifications";
import theme from "src/theme";
import { v4 as uuidv4 } from "uuid";
import { Iso639LanguageCode, OcpiAvailabilityType } from "../LocationEdit/schema";
import { fetchAndProcessLocationData, fetchStationComponents } from "./helpers";
import {
  EvseValues,
  StationValues,
  connectorInitialValues,
  evseInitialValues,
  stationInitialValues,
  validationSchema,
} from "./schema";

interface ValuedListItem<T> {
  name: string;
  value: T;
}

interface StationTemplateMap {
  [id: string]: Array<StationTemplateResponse>;
}

type BrandListItem = ValuedListItem<Array<StationTemplateResponse>>;
type ModelListItem = ValuedListItem<StationTemplateResponse>;

interface ComponentResponseEvse {
  specifications: {
    powerType: string;
    powerMaxCurrent: number;
    powerMaxVoltage: number;
    powerMaxWattage: number;
  };
  connectors: {
    kind: string;
    format: string;
  }[];
}

interface LocationOption {
  id: string;
  countryCode?: string;
  name: string;
  ocpiAvailabilityType?: string;
}

const StationEdit: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { action, id: idFromParams } = useParams();
  const stationIdFromParams = action === "edit" ? idFromParams : undefined;
  const locationIdFromParams = action === "new" ? idFromParams : undefined;

  const pageTitle =
    action === "new" ? intl.formatMessage({ id: "station_add" }) : intl.formatMessage({ id: "station_edit" });

  const user = useContext(UserContext);

  const [stationData, setStationData] = useState<StationValues>(stationInitialValues);
  const [locations, setLocations] = useState<LocationOption[]>([]);
  const [locationEnabled, setLocationEnabled] = useState<boolean>(false);
  const [brands, setBrands] = useState<Array<BrandListItem>>([]);
  const [selectedBrand, setSelectedBrand] = useState<string>();
  const [models, setModels] = useState<Array<ModelListItem>>([]);
  const [networkOptions, setNetworkOpts] = useState<Array<string>>([]);
  const [powerTypes, setPowerTypes] = useState<Array<string>>([]);
  const [connectorTypes, setConnectorTypes] = useState<Array<string>>([]);
  const [connectorFormats, setConnectorFormats] = useState<Array<string>>([]);

  const languageOptions = [
    { label: intl.formatMessage({ id: "iso639LanguageCode_english" }), value: Iso639LanguageCode.English },
    { label: intl.formatMessage({ id: "iso639LanguageCode_thai" }), value: Iso639LanguageCode.Thai },
  ];

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const goToListPage = () => {
    navigate("/stations");
  };

  const goToStationPage = () => {
    navigate(`/stations/${stationIdFromParams ?? stationData.id}`);
  };

  const goToLocationPage = () => {
    navigate(`/locations/${locationIdFromParams ?? stationData.locationId}`);
  };

  const onClose = () => {
    if (action === "edit") {
      goToStationPage();
    } else if (action === "new" && locationIdFromParams) {
      goToLocationPage();
    } else {
      goToListPage();
    }
  };

  const onSubmit = async (params: StationValues) => {
    const {
      id,
      locationId,
      ocppStationId,
      ocppStationPwd,
      brand,
      model,
      additionalInformation,
      roamingEnabled,
      reservationEnabled,
      network,
      powerMaxWattage,
      emi3Name,
      evses,
      profile,
      directions,
    } = params;

    upsertStation({
      id,
      locationId,
      tenantId: user.entityFilter.tenantId,
      ocppStationPwd,
      ocppStationId,
      brand,
      model,
      additionalInformation,
      roamingEnabled,
      reservationEnabled,
      network,
      powerMaxWattage,
      emi3Name,
      evses: evses.map((evse) => ({
        id: evse.id,
        qrCodeId: evse.qrCodeId,
        specifications: {
          powerType: evse.powerType,
          powerMaxCurrent: evse.maxCurrent,
          powerMaxVoltage: evse.maxVoltage,
          powerMaxWattage: evse.maxWattage,
        },
        connectors: evse.connectors.map((connector) => ({
          id: connector.id,
          specifications: {
            kind: connector.kind,
            format: connector.format,
            alias: connector.alias,
          },
        })),
      })),
      profile,
      directions,
    })
      .then(goToStationPage)
      .catch((err) => notifyAxiosError(err, intl));
  };

  const formik = useFormik<StationValues>({
    initialValues: stationData,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const fetchAndSetStationData = async (stationId: string) => {
    let station = stationInitialValues();
    try {
      const response = await getStation(stationId);

      const filteredEvses = response.data.evses.filter((evse) => !evse.deleted);
      const mappedEvses = filteredEvses.map((evse) => ({
        id: evse.id,
        qrCodeId: evse.qrCodeId,
        isQrCodeIdRequired: false,
        powerType: evse.specifications.powerType,
        maxCurrent: evse.specifications.powerMaxCurrent,
        maxVoltage: evse.specifications.powerMaxVoltage,
        maxWattage: evse.specifications.powerMaxWattage,
        connectors: evse.connectors.map((connector) => ({
          id: connector.id,
          kind: connector.specifications.kind,
          format: connector.specifications.format,
          alias: connector.specifications.alias,
        })),
      }));

      station = {
        ...response.data,
        evses: mappedEvses,
      };
      setStationData({
        ...station,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) notifyAxiosError(error, intl);
      if (error instanceof Error) notifyError(String(error.message), intl);
      goToListPage();
    }
    return station;
  };

  const handleBrandChange = (value: BrandListItem | null) => {
    if (value !== null) {
      setSelectedBrand(value.name);
      setModels(
        value.value.map((item) => ({
          name: item.model,
          value: item,
        })),
      );
    }
  };

  const isQrCodeRequired = (locationOpts: LocationOption[], locationId?: string) => {
    const availabilityType = locationOpts.find((l) => l.id === locationId)?.ocpiAvailabilityType;

    if (availabilityType === undefined) return false;
    return availabilityType !== OcpiAvailabilityType.Unpublished;
  };

  const mapCompEvses = (evses: ComponentResponseEvse[]) =>
    evses.map((evse) => ({
      id: uuidv4(),
      powerType: evse.specifications.powerType,
      maxCurrent: evse.specifications.powerMaxCurrent,
      maxVoltage: evse.specifications.powerMaxVoltage,
      maxWattage: evse.specifications.powerMaxWattage,
      isQrCodeIdRequired: isQrCodeRequired(locations, formik.values.locationId),
      qrCodeId: "",
      connectors: evse.connectors.map((connector) => ({
        id: uuidv4(),
        kind: connector.kind,
        format: connector.format,
        alias: "",
      })),
    }));

  const handleModelChange = (model: ModelListItem | null) => {
    formik.setFieldValue("brand", selectedBrand || "");
    formik.setFieldValue("model", model?.name || "");
    formik.setFieldValue("profile", model?.value.profile ?? stationInitialValues().profile);

    if (model?.value && model.value.evses) {
      const mappedCompEvses = mapCompEvses(model.value.evses);
      formik.setFieldValue("evses", mappedCompEvses);
    } else {
      formik.setFieldValue("evses", []);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let station = stationInitialValues();
      if (action === "edit") {
        if (stationIdFromParams) {
          station = await fetchAndSetStationData(stationIdFromParams);
        }
      } else if (locationIdFromParams) {
        formik.setFieldValue("locationId", locationIdFromParams);
      } else {
        setLocationEnabled(true);
      }

      const fetchedLocations = await fetchAndProcessLocationData(user.entityFilter);
      setLocations(fetchedLocations);
      const components = await fetchStationComponents();
      setNetworkOpts(components.networkTypes);
      setPowerTypes(components.powerTypes.map((item) => item.name));
      setConnectorTypes(components.connectorTypes.map((item) => item.name));
      setConnectorFormats(components.connectorFormats);

      const templateMap: StationTemplateMap = {};
      components.templates.forEach((template) => {
        const { brand } = template;
        if (!templateMap[brand]) {
          templateMap[brand] = [];
        }
        templateMap[brand].push(template);
      });

      setBrands(
        Object.entries(templateMap).map(([key, value]) => ({
          name: key.toString(),
          value,
        })),
      );

      const qrCodeRequired = isQrCodeRequired(fetchedLocations, station.locationId);
      formik.values.evses.forEach((_, i) => {
        formik.setFieldValue(`evses[${i}].isQrCodeIdRequired`, qrCodeRequired);
      });
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationIdFromParams]);

  const handleRemoveEvse = (indexToRemove: number) => {
    const updatedEvses = formik.values.evses.filter((_evse, index) => index !== indexToRemove);
    formik.setFieldValue("evses", updatedEvses);
  };

  const handleRemoveConnector = (evseIndex: number, conIndexToRemove: number) => {
    const updatedConnectors = formik.values.evses[evseIndex].connectors.filter(
      (_connector, index) => index !== conIndexToRemove,
    );
    formik.setFieldValue(`evses[${evseIndex}].connectors`, updatedConnectors);
  };

  const toInputUppercase = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = `${e.target.value}`.toUpperCase();
  };

  const selectedLanguages = formik.values.directions?.map((d) => d.language);
  const availableLanguages = languageOptions.filter((option) => !selectedLanguages?.includes(option.value));
  const isAddDirectionButtonDisabled = availableLanguages.length === 0;

  const addDirectionField = () => {
    const directions = formik.values.directions ?? [];
    directions.push({ language: availableLanguages[0].value, text: "" });
    formik.setFieldValue("directions", directions);
  };

  const updateDirectionField = (index: number, field: string, value: string) => {
    const directions = formik.values.directions ?? [];
    directions[index] = { ...directions[index], [field]: value };
    formik.setFieldValue("directions", directions);
  };

  const removeDirectionField = (index: number) => {
    const directions = formik.values.directions ?? [];
    directions.splice(index, 1);
    formik.setFieldValue("directions", directions);
  };

  const resetAllAlias = () => {
    formik.values.evses.forEach((evse, i) => {
      evse.connectors.forEach((_, j) => {
        formik.setFieldValue(`evses[${i}].connectors[${j}].alias`, "");
      });
    });
  };

  return (
    <Page
      title={pageTitle}
      breadcrumbs={[
        { title: "station_pageHeader", link: "/stations" },
        { title: action === "new" ? "station_add" : "station_edit" },
      ]}
    >
      <EntityEditForm
        title={action === "new" ? "station_add" : "station_edit"}
        onClose={onClose}
        onSubmit={formik.handleSubmit}
      >
        <Grid item sm={12} md={12} lg={6} xl={6}>
          <Box display="flex" flexDirection="column">
            <Autocomplete
              disabled={!locationEnabled}
              disablePortal
              options={locations}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              id="location"
              value={locations.find((option) => option.id === formik.values.locationId) || null}
              onChange={(_e, value) => {
                const qrCodeRequired = isQrCodeRequired(locations, value?.id);
                formik.values.evses.forEach((_, i) => {
                  const field = `evses[${i}].qrCodeId`;
                  formik.setFieldError(field, undefined);
                  formik.setFieldTouched(field, false, false);

                  formik.setFieldValue(`evses[${i}].isQrCodeIdRequired`, qrCodeRequired);
                });

                formik.setFieldValue("locationId", value?.id);
                resetAllAlias();
              }}
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  sx={{ marginBottom: 3 }}
                  label={intl.formatMessage({ id: "location_label" })}
                  variant="outlined"
                  error={formik.touched.locationId && Boolean(formik.errors.locationId)}
                  helperText={
                    formik.touched.locationId &&
                    formik.errors.locationId &&
                    intl.formatMessage({ id: formik.errors.locationId })
                  }
                />
              )}
            />

            <FormControlLabel
              sx={{ marginBottom: 1 }}
              control={
                <Checkbox
                  id="roamingEnabled"
                  checked={formik.values.roamingEnabled}
                  onChange={(e: any) => formik.setFieldValue("roamingEnabled", e.target.checked)}
                  color="primary"
                />
              }
              label={intl.formatMessage({ id: "station_enableRoamingLabel" })}
            />

            <FormControlLabel
              sx={{ marginBottom: 1 }}
              control={
                <Checkbox
                  id="reservationEnabled"
                  checked={formik.values.reservationEnabled}
                  onChange={(e: any) => formik.setFieldValue("reservationEnabled", e.target.checked)}
                  color="primary"
                />
              }
              label={intl.formatMessage({ id: "station_enableReservationLabel" })}
            />

            <Autocomplete
              options={networkOptions}
              value={formik.values.network}
              id="locationId"
              onChange={(_e, value) => {
                formik.setFieldValue("network", value);
              }}
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label={intl.formatMessage({ id: "station_networkLabel" })}
                  variant="outlined"
                  error={formik.touched.network && Boolean(formik.errors.network)}
                  helperText={
                    formik.touched.network && formik.errors.network && intl.formatMessage({ id: formik.errors.network })
                  }
                />
              )}
            />

            <TextField
              required
              fullWidth
              autoComplete="ocpp-station-id"
              name="ocppStationId"
              label={intl.formatMessage({ id: "station_ocppIdLabel" })}
              value={formik.values.ocppStationId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.ocppStationId && Boolean(formik.errors.ocppStationId)}
              helperText={
                formik.touched.ocppStationId &&
                formik.errors.ocppStationId &&
                intl.formatMessage({ id: formik.errors.ocppStationId })
              }
            />

            <FormControl fullWidth>
              <InputLabel htmlFor="ocppStationPwd">
                <FormattedMessage id="genericForm_passwordLabel" />
              </InputLabel>
              <OutlinedInput
                autoComplete="one-time-code"
                id="ocppStationPwd"
                label={intl.formatMessage({ id: "station_ocppPasswordLabel" })}
                type={showPassword ? "text" : "password"}
                value={formik.values.ocppStationPwd}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.ocppStationPwd && Boolean(formik.errors.ocppStationPwd)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText>
                {formik.touched.ocppStationPwd &&
                  formik.errors.ocppStationPwd &&
                  intl.formatMessage({ id: formik.errors.ocppStationPwd })}
              </FormHelperText>
            </FormControl>

            <TextField
              fullWidth
              id="additionalInformation"
              name="additionalInformation"
              label={intl.formatMessage({ id: "station_additionalInformationLabel" })}
              value={formik.values.additionalInformation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.additionalInformation && Boolean(formik.errors.additionalInformation)}
              helperText={
                formik.touched.additionalInformation &&
                formik.errors.additionalInformation &&
                intl.formatMessage({ id: formik.errors.additionalInformation })
              }
            />

            {/* Directions Part */}

            {formik.values.directions?.map((dir, index) => (
              <Grid container spacing={2} key={dir.language}>
                <Grid item xs={3} alignItems="flex-start">
                  <TextField
                    fullWidth
                    select
                    id={`directions[${index}.language`}
                    name={`directions[${index}.language`}
                    label={intl.formatMessage({ id: "genericForm_directionLanguageLabel" })}
                    value={dir.language}
                    onChange={(e) => updateDirectionField(index, "language", e.target.value)}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      getIn(formik.touched, `directions[${index}.language`) &&
                        getIn(formik.errors, `directions[${index}.language`),
                    )}
                    helperText={
                      getIn(formik.touched, `directions[${index}.language`) &&
                      getIn(formik.errors, `directions[${index}.language`) &&
                      intl.formatMessage({ id: getIn(formik.errors, `directions[${index}.language`) })
                    }
                  >
                    {languageOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        disabled={selectedLanguages?.includes(option.value)}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={8} alignItems="flex-start">
                  <TextField
                    fullWidth
                    id={`directions[${index}].text`}
                    name={`directions[${index}].text`}
                    label={intl.formatMessage({ id: "genericForm_directionTextLabel" })}
                    value={dir.text}
                    onChange={(e) => updateDirectionField(index, "text", e.target.value)}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      getIn(formik.touched, `directions[${index}].text`) &&
                        getIn(formik.errors, `directions[${index}].text`),
                    )}
                    helperText={
                      getIn(formik.touched, `directions[${index}].text`) &&
                      getIn(formik.errors, `directions[${index}].text`) &&
                      intl.formatMessage({ id: getIn(formik.errors, `directions[${index}].text`) })
                    }
                  />
                </Grid>
                <Grid item xs={1} container alignItems="center" justifyContent="center">
                  <Button onClick={() => removeDirectionField(index)} sx={{ padding: 0, minWidth: "auto" }}>
                    <GridCloseIcon fontSize="small" />
                  </Button>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ borderStyle: "dotted", marginY: 1 }}
                color="primary"
                onClick={addDirectionField}
                disabled={isAddDirectionButtonDisabled}
              >
                {intl.formatMessage({ id: "genericForm_addDirectionsLabel" })}
              </Button>
            </Grid>

            <TextField
              required
              fullWidth
              autoComplete="emi3Name"
              name="emi3Name"
              label={intl.formatMessage({ id: "genericForm_emi3NameLabel" })}
              value={formik.values.emi3Name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.emi3Name && Boolean(formik.errors.emi3Name)}
              helperText={
                formik.touched.emi3Name && formik.errors.emi3Name && intl.formatMessage({ id: formik.errors.emi3Name })
              }
              onInput={toInputUppercase}
            />

            <Typography variant="h6" align="left" marginTop={3} marginLeft={1}>
              <FormattedMessage id="station_evseTemplateHeader" />
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Autocomplete
                  disablePortal
                  options={brands}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.name === value?.name}
                  onChange={(_e, value) => handleBrandChange(value)}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={intl.formatMessage({ id: "station_brandLabel" })}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  options={models}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.name === value?.name}
                  onChange={(_e, value) => handleModelChange(value)}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={intl.formatMessage({ id: "station_modelLabel" })}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <TextField
              required
              fullWidth
              name="brand"
              label={intl.formatMessage({ id: "station_brandLabel" })}
              value={formik.values.brand}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.brand && Boolean(formik.errors.brand)}
              helperText={
                formik.touched.brand && formik.errors.brand && intl.formatMessage({ id: formik.errors.brand })
              }
            />

            <TextField
              required
              fullWidth
              name="model"
              label={intl.formatMessage({ id: "station_modelLabel" })}
              value={formik.values.model}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.model && Boolean(formik.errors.model)}
              helperText={
                formik.touched.model && formik.errors.model && intl.formatMessage({ id: formik.errors.model })
              }
            />

            <FormControlLabel
              control={
                <Checkbox
                  id="enableConnectorZeroEmulation"
                  checked={formik.values.profile.enableConnectorZeroEmulation}
                  onChange={(e: any) => formik.setFieldValue("profile.enableConnectorZeroEmulation", e.target.checked)}
                  color="primary"
                />
              }
              label={intl.formatMessage({ id: "station_enableConnectorZeroEmulationLabel" })}
              style={{ marginBottom: 3 }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  id="useHeartbeatAsPing"
                  checked={formik.values.profile.useHeartbeatAsPing}
                  onChange={(e: any) => formik.setFieldValue("profile.useHeartbeatAsPing", e.target.checked)}
                  color="primary"
                />
              }
              label={intl.formatMessage({ id: "station_useHeartbeatAsPingLabel" })}
              style={{ marginBottom: 3 }}
            />
          </Box>
        </Grid>

        {/* EVSE PART */}

        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => {
              const initValues = evseInitialValues();
              initValues.isQrCodeIdRequired = isQrCodeRequired(locations, formik.values.locationId);
              formik.values.evses.push(initValues);
              formik.setValues({ ...formik.values });
            }}
          >
            <AddOutlinedIcon fontSize="small" />
            <Typography variant="h6" align="center" marginLeft={1}>
              <FormattedMessage id="station_addEvseLabel" />
            </Typography>
          </Button>
        </Grid>

        <Grid item sm={12} lg={12}>
          <Grid container spacing={3}>
            {formik.values.evses?.length > 0 &&
              formik.values.evses.map((evse: EvseValues, index: number) => (
                <Grid item sm={12} md={12} lg={6} xl={6} key={evse.id}>
                  <ElementBox>
                    <Typography
                      variant="h5"
                      marginBottom={1}
                      style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                    >
                      {`EVSE ${+index + 1}`}

                      {formik.values.evses?.length > 1 && (
                        <IconButton
                          edge="end"
                          style={{ color: theme.palette.primary.main }}
                          onClick={() => handleRemoveEvse(index)}
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                    </Typography>

                    <Autocomplete
                      options={powerTypes}
                      id={`evses[${index}].powerType`}
                      value={evse.powerType}
                      onChange={(_e, value) => {
                        formik.setFieldValue(`evses[${index}].powerType`, value);
                      }}
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label={intl.formatMessage({ id: "station_powerTypeLabel" })}
                          variant="outlined"
                          error={Boolean(
                            getIn(formik.touched, `evses[${index}].powerType`) &&
                              getIn(formik.errors, `evses[${index}].powerType`),
                          )}
                          helperText={
                            getIn(formik.touched, `evses[${index}].powerType`) &&
                            getIn(formik.errors, `evses[${index}].powerType`) &&
                            intl.formatMessage({ id: getIn(formik.errors, `evses[${index}].powerType`) })
                          }
                        />
                      )}
                    />

                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <TextField
                          required
                          name={`evses[${index}].maxCurrent`}
                          label={`EVSE ${index + 1} Max Current`}
                          value={evse.maxCurrent}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={Boolean(
                            getIn(formik.touched, `evses[${index}].maxCurrent`) &&
                              getIn(formik.errors, `evses[${index}].maxCurrent`),
                          )}
                          helperText={
                            getIn(formik.touched, `evses[${index}].maxCurrent`) &&
                            getIn(formik.errors, `evses[${index}].maxCurrent`) &&
                            intl.formatMessage({ id: getIn(formik.errors, `evses[${index}].maxCurrent`) })
                          }
                          InputProps={{
                            endAdornment: <InputAdornment position="end">A</InputAdornment>,
                          }}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <TextField
                          required
                          name={`evses[${index}].maxVoltage`}
                          label={`EVSE ${index + 1} Max Voltage`}
                          value={evse.maxVoltage}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={Boolean(
                            getIn(formik.touched, `evses[${index}].maxVoltage`) &&
                              getIn(formik.errors, `evses[${index}].maxVoltage`),
                          )}
                          helperText={
                            getIn(formik.touched, `evses[${index}].maxVoltage`) &&
                            getIn(formik.errors, `evses[${index}].maxVoltage`) &&
                            intl.formatMessage({ id: getIn(formik.errors, `evses[${index}].maxVoltage`) })
                          }
                          InputProps={{
                            endAdornment: <InputAdornment position="end">V</InputAdornment>,
                          }}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <TextField
                          required
                          name={`evses[${index}].maxWattage`}
                          label={`EVSE ${index + 1} Max Wattage`}
                          value={evse.maxWattage}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={Boolean(
                            getIn(formik.touched, `evses[${index}].maxWattage`) &&
                              getIn(formik.errors, `evses[${index}].maxWattage`),
                          )}
                          helperText={
                            getIn(formik.touched, `evses[${index}].maxWattage`) &&
                            getIn(formik.errors, `evses[${index}].maxWattage`) &&
                            intl.formatMessage({ id: getIn(formik.errors, `evses[${index}].maxWattage`) })
                          }
                          InputProps={{
                            endAdornment: <InputAdornment position="end">W</InputAdornment>,
                          }}
                        />
                      </Grid>
                    </Grid>

                    <TextField
                      required={isQrCodeRequired(locations, formik.values.locationId)}
                      fullWidth
                      name={`evses[${index}].qrCodeId`}
                      label={intl.formatMessage({ id: "station_qrCodeIdLabel" })}
                      value={evse.qrCodeId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        getIn(formik.touched, `evses[${index}].qrCodeId`) &&
                          getIn(formik.errors, `evses[${index}].qrCodeId`),
                      )}
                      helperText={
                        getIn(formik.touched, `evses[${index}].qrCodeId`) &&
                        getIn(formik.errors, `evses[${index}].qrCodeId`) &&
                        intl.formatMessage({ id: getIn(formik.errors, `evses[${index}].qrCodeId`) })
                      }
                    />

                    {/* Connector Part */}

                    <Grid item xs={12} marginY={2}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          formik.values.evses[index].connectors.push(connectorInitialValues());
                          formik.setFieldValue(`evses[${index}].connectors`, formik.values.evses[index].connectors);
                        }}
                      >
                        <AddOutlinedIcon fontSize="small" />
                        <Typography variant="h6" align="center" marginLeft={1}>
                          <FormattedMessage id="station_addConnectorLabel" />
                        </Typography>
                      </Button>
                    </Grid>

                    {evse.connectors?.length > 0 &&
                      evse.connectors.map((connector, connectorIndex) => {
                        const conPrefix = `evses[${index}].connectors[${connectorIndex}]`;
                        return (
                          <Grid item sm={12} md={12} lg={9} xl={9} key={connector.id}>
                            <Box
                              sx={{
                                padding: 2,
                                marginY: 1,
                                border: "1px solid",
                                borderColor: theme.palette.grey[300],
                                borderRadius: 1,
                              }}
                            >
                              <Typography
                                variant="h6"
                                marginBottom={1}
                                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                              >
                                {`Connector ${+connectorIndex + 1}`}

                                {formik.values.evses[index].connectors.length > 1 && (
                                  <IconButton
                                    edge="end"
                                    style={{ color: theme.palette.primary.main }}
                                    onClick={() => handleRemoveConnector(index, connectorIndex)}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                )}
                              </Typography>

                              <Autocomplete
                                disablePortal
                                options={connectorTypes}
                                id={`${conPrefix}.kind`}
                                value={connector?.kind}
                                onChange={(_e, value) => {
                                  formik.setFieldValue(`${conPrefix}.kind`, value);
                                }}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label={intl.formatMessage({ id: "station_connectorTypeLabel" })}
                                    variant="outlined"
                                    error={Boolean(
                                      getIn(formik.touched, `${conPrefix}.kind`) &&
                                        getIn(formik.errors, `${conPrefix}.kind`),
                                    )}
                                    helperText={
                                      getIn(formik.touched, `${conPrefix}.kind`) &&
                                      getIn(formik.errors, `${conPrefix}.kind`) &&
                                      intl.formatMessage({ id: getIn(formik.errors, `${conPrefix}.kind`) })
                                    }
                                  />
                                )}
                              />

                              <Autocomplete
                                options={connectorFormats}
                                id={`${conPrefix}.format`}
                                value={connector?.format}
                                onChange={(_e, value) => {
                                  formik.setFieldValue(`${conPrefix}.format`, value);
                                }}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label={intl.formatMessage({ id: "station_connectorFormatLabel" })}
                                    variant="outlined"
                                    error={Boolean(
                                      getIn(formik.touched, `${conPrefix}.format`) &&
                                        getIn(formik.errors, `${conPrefix}.format`),
                                    )}
                                    helperText={
                                      getIn(formik.touched, `${conPrefix}.format`) &&
                                      getIn(formik.errors, `${conPrefix}.format`) &&
                                      intl.formatMessage({ id: getIn(formik.errors, `${conPrefix}.format`) })
                                    }
                                  />
                                )}
                              />

                              {locations.find((l) => l.id === formik.values.locationId)?.countryCode === "SG" && (
                                <>
                                  <TextField
                                    fullWidth
                                    id={`${conPrefix}.alias`}
                                    label={intl.formatMessage({ id: "station_oneMotoringIdLabel" })}
                                    value={connector.alias ?? ""}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  <Typography variant="body2">
                                    {intl.formatMessage({ id: "station_oneMotoringIdExample" })}
                                  </Typography>
                                </>
                              )}
                            </Box>
                          </Grid>
                        );
                      })}
                  </ElementBox>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </EntityEditForm>
    </Page>
  );
};

export default StationEdit;

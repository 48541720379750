import { Box, useTheme } from "@mui/material";
import { Chart, registerables } from "chart.js";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useIntl } from "react-intl";
import { getStationUptime } from "src/api/stations";
import { TimeRangeOption } from "src/components/TimeRangePicker";
import { notifyAxiosError } from "src/components/notifications";
import { SessionsFilter } from "src/components/sessions/interfaces";
import SimpleTabContent from "src/components/tabs/SimpleTabContent";
import { dateFormatters } from "../chart-js/formatters";
import { getBaseLineChartDataset, getBaseLineChartOptions } from "../chart-js/line";
import { getFromDateByRange, getTimeBlockSizeByRange } from "../sessions/range-utilities";
import StationUptimeDataUnavailable from "./StationUptimeDataUnavailable";

Chart.register(...registerables);

const StationUptimeGraphRange: FC<{
  range: TimeRangeOption;
  filter: SessionsFilter;
}> = ({ range, filter }) => {
  const intl = useIntl();
  const { palette } = useTheme();

  const dataEmpty = [{ dateTime: new Date(), value: 0 }];
  const [data, setData] = useState<Array<{ dateTime: Date; value: number }>>(dataEmpty);
  const [dataUnavailable, setDataUnavailable] = useState(false);

  useEffect(() => {
    if (!filter.stationId) {
      return;
    }

    getStationUptime(filter.stationId, {
      from: getFromDateByRange(range),
      timezone: dayjs.tz.guess(),
      timeBlockSize: getTimeBlockSizeByRange(range),
    })
      .then((response) => {
        if (response.data.graph.items.length) {
          setData(
            response.data.graph.items.map(({ dateTime, value }) => ({
              dateTime: dayjs(dateTime).toDate(),
              value: (value as string | number) === "NaN" ? 0 : value,
            })),
          );
        }
      })
      .catch((err) =>
        notifyAxiosError(err, intl, undefined, () => {
          setDataUnavailable(true);
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const baseChartDataSet = getBaseLineChartDataset();

  let maxTicksLimit;
  if (range === "1D") maxTicksLimit = 12;
  if (range === "1M") maxTicksLimit = 17;

  return (
    <Box display="flex" alignItems="center" width="100%" height={300} mt={3}>
      {dataUnavailable ? (
        <StationUptimeDataUnavailable />
      ) : (
        <Line
          data={{
            datasets: [
              {
                ...baseChartDataSet,
                data: data.map((item) => item.value),
                label: intl.formatMessage({ id: "station_uptimeLabel" }),
              },
            ],
            labels: data.map((item) => dateFormatters[range](item.dateTime)),
          }}
          options={{
            ...getBaseLineChartOptions(palette, maxTicksLimit),
            scales: {
              ...getBaseLineChartOptions(palette, maxTicksLimit).scales,
              y: {
                ...getBaseLineChartOptions(palette, maxTicksLimit).scales?.y,
                max: 100,
              },
            },
          }}
        />
      )}
    </Box>
  );
};

const StationUptimeGraphEmbedded: FC<{
  ranges: Array<TimeRangeOption>;
  rangeIndex: number;
  filter: SessionsFilter;
}> = ({ ranges, rangeIndex, filter }) => (
  <SimpleTabContent
    tabs={ranges.map((range) => ({
      key: range,
      child: <StationUptimeGraphRange range={range} filter={filter} />,
    }))}
    selected={rangeIndex}
  />
);

export default StationUptimeGraphEmbedded;

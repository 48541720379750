import { LoaderStatus } from "@googlemaps/js-api-loader";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid-pro";
import { getIn, useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { getLocation, upsertLocation } from "src/api/locations";
import { fetchTariffSummaries } from "src/api/tariffs";
import { TariffSummaryResponse } from "src/api/tariffs/interfaces";
import { countryCodes, countryCodesMap } from "src/assets/data/countryCodes";
import UserContext from "src/components/UserContext";
import EntityEditForm from "src/components/forms/EntityEditForm";
import GoogleAutocomplete from "src/components/googleMaps/GoogleAutocomplete";
import GoogleLibLoader from "src/components/googleMaps/GoogleLibLoader";
import GoogleMap from "src/components/googleMaps/GoogleMaps";
import Page from "src/components/layout/Page";
import { notifyAxiosError } from "src/components/notifications";
import {
  Iso639LanguageCode,
  LoadManagementType,
  LocationFormValues,
  OcpiAvailabilityType,
  initialValues,
  validationSchema,
} from "src/pages/LocationEdit/schema";
import { Can } from "src/services/permissions/context";

const LocationEdit: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const pageParams = useParams();
  const { action, id } = pageParams;
  const actions = ["new", "edit"];
  const user = useContext(UserContext);

  const [googleMapsLoadingStatus, setGoogleMapsLoadingStatus] = useState<LoaderStatus>(LoaderStatus.INITIALIZED);
  const [editFormProps, setEditFormProps] = useState<LocationFormValues>(initialValues);
  const [coordinates, setCoordinates] = useState<google.maps.LatLngLiteral>({ lat: 1.283, lng: 103.833 });
  const [zoom, setZoom] = useState<number>(8);
  const [tariffs, setTariffs] = useState<Array<TariffSummaryResponse>>([]);
  const defaultValues = initialValues();

  const ocpiAvailabilityOptions = [
    { label: intl.formatMessage({ id: "ocpiAvailabilityType_unpublished" }), value: OcpiAvailabilityType.Unpublished },
    { label: intl.formatMessage({ id: "ocpiAvailabilityType_unlisted" }), value: OcpiAvailabilityType.Unlisted },
    { label: intl.formatMessage({ id: "ocpiAvailabilityType_public" }), value: OcpiAvailabilityType.Public },
  ];

  const powerConnectionOptions = [
    { label: intl.formatMessage({ id: "loadManagementType_disabled" }), value: LoadManagementType.Disabled },
    {
      label: intl.formatMessage({ id: "loadManagementType_evenDistribution" }),
      value: LoadManagementType.EvenDistribution,
    },
  ];

  const languageOptions = [
    { label: intl.formatMessage({ id: "iso639LanguageCode_english" }), value: Iso639LanguageCode.English },
    { label: intl.formatMessage({ id: "iso639LanguageCode_thai" }), value: Iso639LanguageCode.Thai },
  ];

  const onCoordinatesChanged = (newCoords: google.maps.LatLngLiteral) => {
    setCoordinates(newCoords);
    setZoom(14);
  };

  const goToListPage = () => {
    navigate("/locations");
  };

  const goToLocationPage = () => {
    navigate(`/locations/${id ?? editFormProps.locationId}`);
  };

  useEffect(() => {
    // need to do navigate statements in useEffect, as useEffect must not be conditionally executed
    if (action && !actions.includes(action)) {
      goToListPage();
      return;
    }

    if (action === "edit") {
      if (id === undefined) {
        // can't edit something without an ID
        goToListPage();
        return;
      }

      getLocation(id)
        .then((response) => {
          setEditFormProps({
            ...response.data,
            locationId: response.data.id,
            contactName: response.data.contactPoint,
            // Location Details Response might return null for powerConnection, so default values are set.
            powerConnectionId: response.data.powerConnection?.id || defaultValues.powerConnectionId,
            powerConnectionLoadManagement:
              response.data.powerConnection?.loadManagement || defaultValues.powerConnectionLoadManagement,
            powerConnectionMaxWattage:
              response.data.powerConnection?.maxWattage || defaultValues.powerConnectionMaxWattage,
          });
          setCoordinates({
            lat: response.data.latitude,
            lng: response.data.longitude,
          });
        })
        .catch((err) => {
          goToListPage();
          notifyAxiosError(err, intl);
        });
    }

    fetchTariffSummaries(user.entityFilter)
      .then((response) => {
        setTariffs(response.data);
      })
      .catch((err) => {
        notifyAxiosError(err, intl);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageTitle = intl.formatMessage({ id: "location_edit" });

  const onClose = action === "edit" ? goToLocationPage : goToListPage;

  const onSubmit = async (params: LocationFormValues) => {
    const {
      locationId,
      name,
      countryCode,
      state,
      city,
      address,
      postalCode,
      contactName,
      contactNumber,
      contactEmail,
      activeTariffIdAc,
      activeTariffIdDc,
      emi3Name,
      ocpiAvailability,
      powerConnectionId,
      powerConnectionLoadManagement,
      powerConnectionMaxWattage,
      directions,
      enableAutoRestart,
    } = params;

    upsertLocation({
      id: locationId,
      tenantId: user.activeTenantId,
      name,
      countryCode,
      state,
      city,
      address,
      postalCode,
      latitude: coordinates.lat,
      longitude: coordinates.lng,
      contactPoint: contactName,
      contactNumber,
      contactEmail,
      activeTariffIdAc,
      activeTariffIdDc,
      tags: [],
      emi3Name,
      ocpiAvailability,
      powerConnection: {
        id: powerConnectionId,
        loadManagement: powerConnectionLoadManagement,
        maxWattage: powerConnectionMaxWattage,
      },
      directions,
      enableAutoRestart,
    })
      .then(goToLocationPage)
      .catch((err) => notifyAxiosError(err, intl));
  };

  const formik = useFormik({
    initialValues: editFormProps,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  // Utility Functions
  const setFormValue = (field: string, value: string) => {
    formik.setFieldValue(field, value);
  };

  const selectedLanguages = (formik.values.directions || []).map((d) => d.language);
  const availableLanguages = languageOptions.filter((option) => !selectedLanguages.includes(option.value));
  const isAddDirectionButtonDisabled = availableLanguages.length === 0;

  const addDirectionField = () => {
    const directions = formik.values.directions ?? [];
    directions.push({ language: availableLanguages[0].value, text: "" });
    formik.setFieldValue("directions", directions);
  };

  const updateDirectionField = (index: number, field: string, value: string) => {
    const directions = formik.values.directions ?? [];
    directions[index] = { ...directions[index], [field]: value };
    formik.setFieldValue("directions", directions);
  };

  const removeDirectionField = (index: number) => {
    const directions = formik.values.directions ?? [];
    directions.splice(index, 1);
    formik.setFieldValue("directions", directions);
  };

  const toInputUppercase = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = `${e.target.value}`.toUpperCase();
  };

  return (
    <Page
      title={pageTitle}
      breadcrumbs={[
        { title: "location_pageHeader", link: "/locations" },
        { title: action === "new" ? "location_add" : "location_edit" },
      ]}
    >
      <GoogleLibLoader setLoadingStatus={setGoogleMapsLoadingStatus} />
      <EntityEditForm
        title={action === "new" ? "location_add" : "location_edit"}
        onClose={onClose}
        onSubmit={formik.handleSubmit}
      >
        <Grid item md={7} xs={12}>
          <Box display="flex" flexDirection="column">
            <TextField
              fullWidth
              required
              id="name"
              name="name"
              label={intl.formatMessage({ id: "location_nameLabel" })}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name && intl.formatMessage({ id: formik.errors.name })}
            />

            <Autocomplete
              disablePortal
              options={countryCodes}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              id="countryCode"
              value={countryCodesMap.get(formik.values.countryCode)}
              onChange={(_e, value) => formik.setFieldValue("countryCode", value?.id)}
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ marginBottom: 3 }}
                  label={intl.formatMessage({ id: "location_countryLabel" })}
                  error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
                  helperText={
                    formik.touched.countryCode &&
                    formik.errors.countryCode &&
                    intl.formatMessage({ id: formik.errors.countryCode })
                  }
                />
              )}
            />

            <GoogleAutocomplete
              loaderStatus={googleMapsLoadingStatus}
              countryCode={formik.values.countryCode}
              setCoordinates={onCoordinatesChanged}
              setState={(val) => setFormValue("state", val)}
              setCity={(val) => setFormValue("city", val)}
              setAddress={(val) => setFormValue("address", val)}
              setPostalCode={(val) => setFormValue("postalCode", val)}
            />

            <TextField
              fullWidth
              id="state"
              name="state"
              label={intl.formatMessage({ id: "location_stateLabel" })}
              value={formik.values.state ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={
                formik.touched.state && formik.errors.state && intl.formatMessage({ id: formik.errors.state })
              }
            />

            <TextField
              fullWidth
              required
              id="city"
              name="city"
              label={intl.formatMessage({ id: "location_cityLabel" })}
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city && intl.formatMessage({ id: formik.errors.city })}
            />

            <TextField
              fullWidth
              required
              id="address"
              name="address"
              label={intl.formatMessage({ id: "location_primaryAddressLabel" })}
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={
                formik.touched.address && formik.errors.address && intl.formatMessage({ id: formik.errors.address })
              }
            />

            <TextField
              fullWidth
              required
              id="postalCode"
              name="postalCode"
              label={intl.formatMessage({ id: "location_postalLabel" })}
              value={formik.values.postalCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
              helperText={
                formik.touched.postalCode &&
                formik.errors.postalCode &&
                intl.formatMessage({ id: formik.errors.postalCode })
              }
            />

            <TextField
              fullWidth
              id="contactName"
              name="contactName"
              label={intl.formatMessage({ id: "location_contactPointLabel" })}
              value={formik.values.contactName ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.contactName && Boolean(formik.errors.contactName)}
              helperText={
                formik.touched.contactName &&
                formik.errors.contactName &&
                intl.formatMessage({ id: formik.errors.contactName })
              }
            />

            <TextField
              fullWidth
              id="contactNumber"
              name="contactNumber"
              label={intl.formatMessage({ id: "location_contactNumberLabel" })}
              value={formik.values.contactNumber ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
              helperText={
                formik.touched.contactNumber &&
                formik.errors.contactNumber &&
                intl.formatMessage({ id: formik.errors.contactNumber })
              }
            />

            <TextField
              fullWidth
              id="contactEmail"
              name="contactEmail"
              label={intl.formatMessage({ id: "location_contactEmailLabel" })}
              value={formik.values.contactEmail ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.contactEmail && Boolean(formik.errors.contactEmail)}
              helperText={
                formik.touched.contactEmail &&
                formik.errors.contactEmail &&
                intl.formatMessage({ id: formik.errors.contactEmail })
              }
            />

            <TextField
              fullWidth
              required
              id="emi3Name"
              name="emi3Name"
              label={intl.formatMessage({ id: "genericForm_emi3NameLabel" })}
              value={formik.values.emi3Name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.emi3Name && Boolean(formik.errors.emi3Name)}
              helperText={
                formik.touched.emi3Name && formik.errors.emi3Name && intl.formatMessage({ id: formik.errors.emi3Name })
              }
              onInput={toInputUppercase}
            />
          </Box>

          <Autocomplete
            disablePortal
            id="ocpiAvailability"
            options={ocpiAvailabilityOptions}
            defaultValue={ocpiAvailabilityOptions.find((option) => option.value === OcpiAvailabilityType.Unpublished)}
            value={ocpiAvailabilityOptions.find((option) => option.value === formik.values.ocpiAvailability)}
            onChange={(_e, value) => formik.setFieldValue("ocpiAvailability", value?.value)}
            onBlur={formik.handleBlur}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                sx={{ marginBottom: 3 }}
                label={intl.formatMessage({ id: "location_ocpiAvailabilityLabel" })}
                error={formik.touched.ocpiAvailability && Boolean(formik.errors.ocpiAvailability)}
                helperText={
                  formik.touched.ocpiAvailability &&
                  formik.errors.ocpiAvailability &&
                  intl.formatMessage({ id: formik.errors.ocpiAvailability })
                }
              />
            )}
          />
        </Grid>
        <Grid item md={5} xs={12}>
          <Box height="300px">
            <GoogleMap
              loaderStatus={googleMapsLoadingStatus}
              coords={coordinates}
              setCoords={setCoordinates}
              zoom={zoom}
            />
          </Box>
          <TextField
            disabled
            fullWidth
            id="location"
            label={intl.formatMessage({ id: "location_latitudeLongitudeLabel" })}
            value={`${coordinates.lat}, ${coordinates.lng}`}
          />

          <Can I="update" a="Tariff">
            {/* Autocomplete works. Seeing Uncontrollable input error on console. Could be due to null formik value. */}
            <Autocomplete
              disablePortal
              options={tariffs}
              getOptionLabel={(option) => option.name}
              value={tariffs.find((tariff) => tariff.name === formik.values.activeTariffNameAc) || null}
              isOptionEqualToValue={(option, value) => option.activeId === value.activeId}
              onChange={(_event, value) => {
                const selectedTariff = tariffs.find((tariff) => tariff.name === value?.name);
                formik.setFieldValue("activeTariffNameAc", selectedTariff?.name);
                formik.setFieldValue("activeTariffIdAc", selectedTariff?.activeId);
              }}
              id="activeTariffNameAc"
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ marginBottom: 3 }}
                  label={intl.formatMessage({ id: "location_tariffAcLabel" })}
                  error={formik.touched.activeTariffNameAc && Boolean(formik.errors.activeTariffNameAc)}
                  helperText={
                    formik.touched.activeTariffNameAc &&
                    formik.errors.activeTariffNameAc &&
                    intl.formatMessage({ id: formik.errors.activeTariffNameAc })
                  }
                />
              )}
            />

            <Autocomplete
              disablePortal
              options={tariffs}
              getOptionLabel={(option) => option.name}
              value={tariffs.find((tariff) => tariff.name === formik.values.activeTariffNameDc) || null}
              isOptionEqualToValue={(option, value) => option.activeId === value.activeId}
              onChange={(_event, value) => {
                const selectedTariff = tariffs.find((tariff) => tariff.name === value?.name);
                formik.setFieldValue("activeTariffNameDc", selectedTariff?.name);
                formik.setFieldValue("activeTariffIdDc", selectedTariff?.activeId);
              }}
              id="activeTariffNameDc"
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ marginBottom: 3 }}
                  label={intl.formatMessage({ id: "location_tariffDcLabel" })}
                  error={formik.touched.activeTariffNameDc && Boolean(formik.errors.activeTariffNameDc)}
                  helperText={
                    formik.touched.activeTariffNameDc &&
                    formik.errors.activeTariffNameDc &&
                    intl.formatMessage({ id: formik.errors.activeTariffNameDc })
                  }
                />
              )}
            />
          </Can>

          <TextField
            fullWidth
            id="powerConnectionLoadManagement"
            name="powerConnectionLoadManagement"
            select
            label={intl.formatMessage({ id: "location_powerConnectionLoadManagementLabel" })}
            value={formik.values.powerConnectionLoadManagement}
            onChange={(e) => formik.setFieldValue("powerConnectionLoadManagement", e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.touched.powerConnectionLoadManagement && Boolean(formik.errors.powerConnectionLoadManagement)}
            helperText={
              formik.touched.powerConnectionLoadManagement &&
              formik.errors.powerConnectionLoadManagement &&
              intl.formatMessage({ id: formik.errors.powerConnectionLoadManagement })
            }
          >
            {powerConnectionOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            id="powerConnectionMaxWattage"
            label={intl.formatMessage({ id: "location_powerConnectionMaxWattageLabel" })}
            value={formik.values.powerConnectionMaxWattage}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.powerConnectionMaxWattage && Boolean(formik.errors.powerConnectionMaxWattage)}
            helperText={
              formik.touched.powerConnectionMaxWattage &&
              formik.errors.powerConnectionMaxWattage &&
              intl.formatMessage({ id: formik.errors.powerConnectionMaxWattage })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {intl.formatMessage({ id: "location_powerConnectionMaxWattageUnit" })}
                </InputAdornment>
              ),
            }}
          />
          <Can I="control" a="Location">
            <FormControlLabel
              control={
                <Checkbox
                  id="enableAutoRestart"
                  checked={formik.values.enableAutoRestart}
                  onChange={(e: any) => formik.setFieldValue("enableAutoRestart", e.target.checked)}
                  color="primary"
                />
              }
              label={intl.formatMessage({ id: "location_enableAutoRestartLabel" })}
              style={{ marginBottom: 3 }}
            />
          </Can>

          {formik.values.directions?.map((dir, index) => (
            <Grid container spacing={2} key={dir.language}>
              <Grid item xs={3} alignItems="flex-start">
                <TextField
                  fullWidth
                  select
                  id={`directions[${index}].language`}
                  name={`directions[${index}].language`}
                  label={intl.formatMessage({ id: "genericForm_directionLanguageLabel" })}
                  value={dir.language}
                  onChange={(e) => updateDirectionField(index, "language", e.target.value)}
                  onBlur={formik.handleBlur}
                  error={Boolean(
                    getIn(formik.touched, `directions[${index}].language`) &&
                      getIn(formik.errors, `directions[${index}].language`),
                  )}
                  helperText={
                    getIn(formik.touched, `directions[${index}].language`) &&
                    getIn(formik.errors, `directions[${index}].language`) &&
                    intl.formatMessage({ id: getIn(formik.errors, `directions[${index}].language`) })
                  }
                >
                  {languageOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      disabled={selectedLanguages.includes(option.value)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={8} alignItems="flex-start">
                <TextField
                  fullWidth
                  id={`directions[${index}].text`}
                  name={`directions[${index}].text`}
                  label={intl.formatMessage({ id: "genericForm_directionTextLabel" })}
                  value={dir.text}
                  onChange={(e) => updateDirectionField(index, "text", e.target.value)}
                  onBlur={formik.handleBlur}
                  error={Boolean(
                    getIn(formik.touched, `directions[${index}].text`) &&
                      getIn(formik.errors, `directions[${index}].text`),
                  )}
                  helperText={
                    getIn(formik.touched, `directions[${index}].text`) &&
                    getIn(formik.errors, `directions[${index}].text`) &&
                    intl.formatMessage({ id: getIn(formik.errors, `directions[${index}].text`) })
                  }
                />
              </Grid>
              <Grid item xs={1} container alignItems="center" justifyContent="center">
                <Button onClick={() => removeDirectionField(index)} sx={{ padding: 0, minWidth: "auto" }}>
                  <GridCloseIcon fontSize="small" />
                </Button>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              sx={{ borderStyle: "dotted" }}
              color="primary"
              onClick={addDirectionField}
              disabled={isAddDirectionButtonDisabled}
            >
              {intl.formatMessage({ id: "genericForm_addDirectionsLabel" })}
            </Button>
          </Grid>
        </Grid>
      </EntityEditForm>
    </Page>
  );
};

export default LocationEdit;

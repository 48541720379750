import { Box, Grid } from "@mui/material";
import { FC, useContext } from "react";
import { useIntl } from "react-intl";
import UserContext from "src/components/UserContext";
import Page from "src/components/layout/Page";
import SessionsTable from "src/components/sessions/SessionsTable";

const Sessions: FC = () => {
  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: "session_pageHeader" });

  const user = useContext(UserContext);

  return (
    <Page title={pageTitle} breadcrumbs={[]}>
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={12} lg={12}>
            <SessionsTable filter={user.entityFilter} />
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Sessions;

import { Grid, SvgIcon, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { FC } from "react";
import { useIntl } from "react-intl";
import { EvseDetailsResponse } from "src/api/stations/interface";
import { ReactComponent as PowerIcon } from "src/assets/images/power-icon.svg";
import ConnectorIcon from "../ConnectorIcon";

const EvseCardConnectorInfo: FC<{ evse: EvseDetailsResponse }> = ({ evse }) => {
  const { palette } = useTheme();
  const intl = useIntl();
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <SvgIcon component={PowerIcon} inheritViewBox fontSize="medium" sx={{ color: palette.grey[500] }} />
          </Grid>

          <Grid item color={palette.grey[500]}>
            {intl.formatMessage({ id: "evseDetails_maxPowerXxx" }, { xxx: evse.specifications.powerMaxWattage / 1000 })}
          </Grid>

          <Grid item color={palette.grey[500]}>
            {evse.specifications.powerType.split("_")[0]}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        {evse.connectors.map((connector) => (
          <ConnectorIcon
            key={`${evse.id}-${connector.id}-connector-icon`}
            kind={connector.specifications.kind}
            status={connector.status}
            statusOcpp={connector.statusOcpp}
            statusUpdatedAt={dayjs(connector.statusUpdatedAt)}
            evseStatus={evse.status}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default EvseCardConnectorInfo;

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import React, { FC, useEffect, useReducer, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getStationOcppLogs } from "src/api/logs";
import { notifyAxiosError } from "src/components/notifications";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type OcppLogsTableItem = {
  ocppStationId: string;
  timestamp: string;
  actionType: string;
  ocppLogType: string;
  messageId: string;
  data: Map<string, any>;
};

export enum StationLogMessageTypes {
  ANY = "ANY",
  REQUEST = "REQUEST",
  RESPONSE = "RESPONSE",
  ERROR = "ERROR",
}

enum StationLogActionTypes {
  ANY = "Any",
  AUTHORIZE = "Authorize",
  BOOT_NOTIFICATION = "Boot Notification",
  DIAGNOSTICS_STATUS_NOTIFICATION = "Diagnostics Status Notification",
  FIRMWARE_STATUS_NOTIFICATION = "Firmware Status Notification",
  HEARTBEAT = "Heartbeat",
  METER_VALUES = "Meter Values",
  START_TRANSACTION = "Start Transaction",
  STATUS_NOTIFICATION = "Status Notification",
  STOP_TRANSACTION = "Stop Transaction",
  OTHERS = "Others",
}

const StationOcppLogsTable: FC<{ id: string }> = ({ id }) => {
  const intl = useIntl();

  const [logData, setLogData] = useState<Array<OcppLogsTableItem>>([]);

  const [selectedActionTypes, setSelectedActionTypes] = useState<StationLogActionTypes[]>([StationLogActionTypes.ANY]);

  const logDataReducer = (
    state: Array<OcppLogsTableItem>,
    action: { type: any; items?: any; messageType: string; actionTypes: StationLogActionTypes[] },
  ) => {
    switch (action.type) {
      case "SET":
        return action.items;
      case "FILTER":
        return logData.filter(
          (item) =>
            ((action.messageType === StationLogMessageTypes.ANY || item.ocppLogType === action.messageType) &&
              action.actionTypes.some((i) => i === StationLogActionTypes.ANY)) ||
            action.actionTypes.some((i) =>
              item.actionType
                .replaceAll(" ", "")
                .toLocaleLowerCase()
                .includes(i.replaceAll(" ", "").toLocaleLowerCase()),
            ) ||
            Object.values(StationLogActionTypes).every(
              (i) =>
                !item.actionType
                  .replaceAll(" ", "")
                  .toLocaleLowerCase()
                  .includes(i.replaceAll(" ", "").toLocaleLowerCase()) &&
                action.actionTypes.some((j) => j === StationLogActionTypes.OTHERS),
            ),
        );
      default:
        throw new Error();
    }
  };

  const [logDataFiltered, dispatch] = useReducer(logDataReducer, []);

  const [messageTypeFilter, setMessageTypeFilter] = useState<string>(StationLogMessageTypes.ANY);

  const [savedNextPageToken, setNextPageToken] = useState<string>();

  const [startDateTime, setStartDateTime] = useState<Dayjs | null>(dayjs().startOf("day"));

  const [endDateTime, setEndDateTime] = useState<Dayjs | null>(dayjs().endOf("day"));

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [rowOffset, setRowOffset] = useState(10);

  const resetPage = (newPage: number) => {
    setPage(newPage);
    setRowOffset((newPage + 1) * rowsPerPage);
  };

  const filterByMessageType = (messageTypeInput: string) => {
    setMessageTypeFilter(messageTypeInput);
    dispatch({
      type: "FILTER",
      messageType: messageTypeInput,
      actionTypes: selectedActionTypes,
    });
  };

  const filterByActionType = (actionTypeInputList: StationLogActionTypes[]) => {
    setSelectedActionTypes(actionTypeInputList);
    dispatch({
      type: "FILTER",
      messageType: messageTypeFilter,
      actionTypes: actionTypeInputList,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);

    if ((newPage + 1) * rowsPerPage <= logDataFiltered.length) setRowOffset((newPage + 1) * rowsPerPage);
    else if (savedNextPageToken === "") setRowOffset(logData.length);
    else {
      getStationOcppLogs({
        stationId: id,
        startTime: startDateTime?.toISOString(),
        endTime: endDateTime?.toISOString(),
        nextPageToken: savedNextPageToken,
      }).then((response) => {
        const newLogData = logData.concat(
          response.data.items
            .sort((s2, s1) => dayjs(s1.timestamp).unix() - dayjs(s2.timestamp).unix())
            .map(({ ocppStationId, timestamp, actionType, ocppLogType, messageId, data }) => ({
              ocppStationId,
              timestamp: dayjs(timestamp).format("L LTS"),
              actionType,
              ocppLogType,
              messageId,
              data,
            })),
        );
        setLogData(newLogData);
        filterByMessageType(messageTypeFilter);
        filterByActionType(selectedActionTypes);
        resetPage(newPage);
        setNextPageToken(response.data.nextPageToken);
      });
    }
  };

  useEffect(() => {
    getStationOcppLogs({
      stationId: id,
      startTime: startDateTime?.toISOString(),
      endTime: endDateTime?.toISOString(),
    })
      .then((response) => {
        setLogData(
          response.data.items
            .sort((s2, s1) => dayjs(s1.timestamp).unix() - dayjs(s2.timestamp).unix())
            .map(({ ocppStationId, timestamp, actionType, ocppLogType, messageId, data }) => ({
              ocppStationId,
              timestamp: dayjs(timestamp).format("L LTS"),
              actionType,
              ocppLogType,
              messageId,
              data,
            })),
        );
        filterByMessageType(messageTypeFilter);
        filterByActionType(selectedActionTypes);
        resetPage(0);
        setNextPageToken(response.data.nextPageToken);
      })
      .catch((err) => notifyAxiosError(err, intl));
    // data should only be reloaded when datetime range changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateTime, endDateTime]);

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3">
              <FormattedMessage id="ocppLogs_label" />
            </Typography>
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} lg={3}>
              <DateTimePicker
                label={intl.formatMessage({ id: "timeRange_startLabel" })}
                value={startDateTime}
                slotProps={{ textField: { fullWidth: true } }}
                onAccept={(value) => setStartDateTime(value)}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <DateTimePicker
                label={intl.formatMessage({ id: "timeRange_endLabel" })}
                value={endDateTime}
                slotProps={{ textField: { fullWidth: true } }}
                onAccept={(value) => setEndDateTime(value)}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                id="input-message-type"
                select
                label={intl.formatMessage({ id: "ocppLogs_messageTypeLabel" })}
                InputProps={{ id: "input-message-type" }}
                InputLabelProps={{ htmlFor: "input-id" }}
                SelectProps={{ inputProps: { id: "input-id" } }}
                fullWidth
                defaultValue={StationLogMessageTypes.ANY}
                onChange={(event) => {
                  filterByMessageType(event.target.value);
                  resetPage(0);
                }}
              >
                {Object.keys(StationLogMessageTypes).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Autocomplete
                multiple
                id="ocppLogActionFilter"
                options={Object.values(StationLogActionTypes)}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                value={selectedActionTypes}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(_e, newValue) => {
                  filterByActionType(newValue);
                }}
                renderOption={(props, option) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <li {...props}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selectedActionTypes.includes(option)}
                        />
                      }
                      label={option}
                    />
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label={intl.formatMessage({ id: "ocppLogs_actionTypeLabel" })} />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} minHeight={100}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="ocppLogs_timestampLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="ocppLogs_messageIdLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="ocppLogs_messageTypeLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="ocppLogs_actionTypeLabel" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="ocppLogs_payloadLabel" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logDataFiltered.slice(page * rowsPerPage, rowOffset).map((row: OcppLogsTableItem) => (
                    <TableRow
                      key={row.messageId + row.ocppLogType}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{row.timestamp}</TableCell>
                      <TableCell>{row.messageId}</TableCell>
                      <TableCell>{row.ocppLogType}</TableCell>
                      <TableCell>{row.actionType}</TableCell>
                      <TableCell>{JSON.stringify(row.data)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={savedNextPageToken === "" ? logDataFiltered.length : -1}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                  inputProps: {
                    id: "select-rows-per-page",
                  },
                }}
                labelDisplayedRows={(pageInfo) =>
                  pageInfo.count !== -1
                    ? intl.formatMessage(
                        { id: "genericTable_paginationEndXyz" },
                        { from: pageInfo.from, to: pageInfo.to, total: pageInfo.count },
                      )
                    : intl.formatMessage(
                        { id: "genericTable_paginationXyz" },
                        { from: pageInfo.from, to: pageInfo.to, total: logDataFiltered.length },
                      )
                }
              />
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StationOcppLogsTable;

import * as Yup from "yup";

export interface UserFormValues {
  email: string;
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

export const initialValues = {
  email: "",
  oldPassword: "",
  password: "",
  confirmPassword: "",
};

export interface UserPasswordResetFormValues {
  password: string;
  confirmPassword: string;
}

export const UserPasswordResetInitialValues = {
  password: "",
  confirmPassword: "",
};

const fields = {
  email: Yup.string().email("userActionForm_validEmailRequired").max(255).required("genericForm_missingField"),
  password: Yup.string().max(255).required("genericForm_missingField"),
  newPassword: Yup.string()
    .max(255)
    .required("genericForm_missingField")
    // regex from https://stackoverflow.com/a/58767981
    .matches(
      /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{6,}$/,
      "userActionForm_validPasswordRequired",
    ),
  confirmPassword: Yup.string()
    .max(255)
    .required("genericForm_missingField")
    .oneOf([Yup.ref("password"), ""], "userActionForm_passwordsMustMatch"),
};

export const loginValidation = Yup.object().shape({
  email: fields.email,
  password: fields.password,
});

export const forgotPasswordValidation = Yup.object().shape({
  email: fields.email,
});

export const changePasswordValidation = Yup.object().shape({
  oldPassword: fields.password,
  password: fields.newPassword,
  confirmPassword: fields.confirmPassword,
});

export const resetPasswordValidation = Yup.object().shape({
  password: fields.newPassword,
  confirmPassword: fields.confirmPassword,
});

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import { Box, Button, Card, CardContent, Link, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FC, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { getLocationSummaries } from "src/api/locations";
import { LocationEvseStatus } from "src/api/locations/interfaces";
import UserContext from "src/components/UserContext";
import { notifyAxiosError } from "src/components/notifications";
import { SocketEventName, socketWrapper } from "src/providers/SocketProvider";
import { Can } from "src/services/permissions/context";
import { statusColors } from "src/theme/colors";

type LocationTableItem = {
  id: string;
  name: string;
  address: string;
  stations: number;
  evses: number;
  status: string;
};

const locationStatus = (evses: LocationEvseStatus) => {
  if (evses.faulted > 0 || evses.unavailable > 0 || evses.total === 0) {
    return "evseStatus_unavailable";
  }

  return "evseStatus_available";
};

const LocationsTable: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const user = useContext(UserContext);
  const [data, setData] = useState<Array<LocationTableItem>>([]);

  const fetchData = () => {
    getLocationSummaries(user.entityFilter)
      .then((response) => {
        setData(
          response.data.map(({ id, name, address, stations, evses }) => ({
            id,
            name,
            address,
            stations: stations.total,
            evses: evses.total,
            status: locationStatus(evses),
          })),
        );
      })
      .catch((err) => notifyAxiosError(err, intl));
  };

  useEffect(() => {
    socketWrapper.onEvent(SocketEventName.EvseState, fetchData);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardContent>
        <Box display="flex" flexDirection="row" flexGrow={1} justifyContent="space-between">
          <Typography variant="h3" align="left" marginBottom={1}>
            <FormattedMessage id="location_pageHeader" />
          </Typography>
          <Can I="create" a="Location">
            <Button variant="contained" onClick={() => navigate("/locations/form/new")}>
              <AddOutlinedIcon fontSize="small" />
              <Typography variant="h6" align="center" marginLeft={1}>
                <FormattedMessage id="location_add" />
              </Typography>
            </Button>
          </Can>
        </Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="location_label" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="location_addressLabel" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="station_pageHeader" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="location_evseLabel" />
                </TableCell>
                <TableCell align="center">
                  <FormattedMessage id="generic_statusLabel" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <Link underline="hover" href={`/locations/${row.id}`}>
                      {row.name}
                    </Link>
                  </TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell align="right">{row.stations}</TableCell>
                  <TableCell align="right">{row.evses}</TableCell>
                  <TableCell align="center">
                    <CircleIcon fontSize="small" sx={{ color: statusColors.get(row.status) }} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default LocationsTable;

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { StationDetailsResponse } from "src/api/stations/interface";
import { Can } from "src/services/permissions/context";

export interface StationInfoProps {
  station: StationDetailsResponse;
}

const StationInfo: FC<StationInfoProps> = ({ station }) => {
  const navigate = useNavigate();
  const { palette } = useTheme();

  const rows = [
    {
      label: <FormattedMessage id="location_label" />,
      value: (
        <Link color="inherit" underline="hover" href={`/locations/${station.locationId}`}>
          {station.locationName}
        </Link>
      ),
    },
    {
      label: <FormattedMessage id="station_modelLabel" />,
      value: `${station.brand} ${station.model}`,
    },
    {
      label: <FormattedMessage id="station_networkLabel" />,
      value: station.network,
    },
    {
      label: <FormattedMessage id="generic_statusLabel" />,
      value: station.status,
    },
    {
      label: <FormattedMessage id="station_additionalInformationLabel" />,
      value: station.additionalInformation,
    },
  ];

  return (
    <Card style={{ height: "100%", backgroundColor: palette.grey[200] }}>
      <CardContent>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center" m={0} p={0}>
              <Grid item>
                <Typography variant="h2">{station.ocppStationId}</Typography>
              </Grid>

              <Grid item>
                <Can I="update" a="Station">
                  <IconButton onClick={() => navigate(`/stations/form/edit/${station.id}`)} color="primary">
                    <SettingsOutlinedIcon />
                  </IconButton>
                </Can>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <TableContainer component={Box}>
              <Table>
                <TableBody>
                  {rows.map((row, index) => (
                    // The order will not change because it is hardcoded. Hence,
                    // using index is safe.
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          padding: 0,
                          paddingBottom: "16px",
                          paddingRight: "8px",
                          borderBottom: "none",
                        }}
                      >
                        <Typography variant="body2" color={palette.primary.dark}>
                          {row.label}
                        </Typography>
                      </TableCell>

                      <TableCell
                        style={{
                          padding: 0,
                          paddingBottom: "16px",
                          borderBottom: "none",
                        }}
                      >
                        <Typography variant="body2" color={palette.grey[500]}>
                          {row.value}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default StationInfo;

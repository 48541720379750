import { Grid, Tooltip } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { FC, ReactElement } from "react";
import { useIntl } from "react-intl";
import { statusDefinitions } from "src/theme/colors";

dayjs.extend(localizedFormat);

interface StatusInfoTooltipProps {
  status: string;
  statusOcpp: string;
  statusUpdatedAt: Dayjs;
  children: ReactElement;
}

const getLabelKey = (status: string): string =>
  statusDefinitions.find((definition) => status.toLowerCase() === definition.key)?.key ?? "unknown";

const StatusInfoTooltip: FC<StatusInfoTooltipProps> = ({ status, statusOcpp, statusUpdatedAt, children }) => {
  const intl = useIntl();
  return (
    <Tooltip
      arrow
      title={
        <Grid container direction="column">
          <Grid item>
            {intl.formatMessage(
              { id: "evse_statusXxx" },
              { Xxx: intl.formatMessage({ id: `evseStatus_${getLabelKey(status)}` }) },
            )}
          </Grid>
          <Grid item>{intl.formatMessage({ id: "evse_ocppStatusXxx" }, { Xxx: statusOcpp })}</Grid>
          <Grid item>{intl.formatMessage({ id: "evse_lastUpdatedXxx" }, { Xxx: statusUpdatedAt.format("lll") })}</Grid>
        </Grid>
      }
    >
      {children}
    </Tooltip>
  );
};

export default StatusInfoTooltip;

import dayjs, { Dayjs } from "dayjs";
import * as Yup from "yup";

export interface ReportFilterValues {
  filterByLocation: boolean;
  locationId?: string;
  filterByStation: boolean;
  stationId?: string;
  filterByRFID: boolean;
  rfid?: string;
  fromDate: Dayjs;
  untilDate: Dayjs;
}

export const initialValues: ReportFilterValues = {
  filterByLocation: false,
  filterByStation: false,
  filterByRFID: false,
  fromDate: dayjs().subtract(1, "month").startOf("month"),
  untilDate: dayjs().subtract(1, "month").endOf("month"),
};

const fields = {
  filterByLocation: Yup.boolean(),
  locationId: Yup.string().when("filterByLocation", {
    is: true,
    then: () => Yup.string().required("genericForm_missingField"),
  }),
  filterByStation: Yup.boolean(),
  stationId: Yup.string().when("filterByStation", {
    is: true,
    then: () => Yup.string().required("genericForm_missingField"),
  }),
  filterByRFID: Yup.boolean(),
  rfid: Yup.string().when("filterByRFID", {
    is: true,
    then: () => Yup.string().required("genericForm_missingField"),
  }),
  fromDate: Yup.date().required(),
  untilDate: Yup.date().required(),
};

export const validationSchema = Yup.object().shape({
  ...fields,
});

import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { getSession } from "src/api/sessions";
import { SessionResponse, sessionResponseEmpty } from "src/api/sessions/interfaces";
import { getTariff } from "src/api/tariffs";
import Page from "src/components/layout/Page";
import { notifyAxiosError } from "src/components/notifications";
import SessionInfo from "src/components/sessions/SessionInfo";

const SessionDetails: FC = () => {
  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: "sessionDetails_pageHeader" });

  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState<SessionResponse>(sessionResponseEmpty);
  const [hasIdlingFee, setHasIdlingFee] = useState<boolean>(false);

  useEffect(() => {
    if (!id) {
      navigate("/sessions");
      return;
    }

    getSession(id)
      .then((response) => {
        setData(response.data);

        if (response.data.tariffId) {
          getTariff(response.data.tariffId)
            .then((tariff) => {
              setHasIdlingFee(tariff.data.parkingHourFee > 0);
            })
            .catch((err) => notifyAxiosError(err, intl));
        }
      })
      .catch((err) => notifyAxiosError(err, intl));
  }, [id, intl, navigate]);

  return (
    <Page
      title={pageTitle}
      breadcrumbs={[{ title: "session_pageHeader", link: "/sessions" }, { title: "sessionDetails_pageHeader" }]}
    >
      <SessionInfo session={data} hasIdlingFee={hasIdlingFee} />
    </Page>
  );
};

export default SessionDetails;

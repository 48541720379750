import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IntlShape } from "react-intl";
import { axiosInstance } from "src/api";
import { notifyError, notifySuccess } from "src/components/notifications";

interface UpdateChargingProfileRequest {
  unit?: "A" | "W";
  limit?: number;
}

const useUpdateChargingProfile = (intl: IntlShape, stationId: string, evseId: string) =>
  useMutation({
    mutationKey: ["updateEvseLimit"],
    mutationFn: (profile: UpdateChargingProfileRequest) =>
      axiosInstance.post<string>(`station/${stationId}/evse/${evseId}/debug/set_charging_profile`, { ...profile }),
    onSuccess: () => notifySuccess("chargingProfile_updateSuccesful", intl),
    onError: (error) => notifyError((error as AxiosError).code!, intl),
  });

export default useUpdateChargingProfile;

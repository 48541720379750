import { LoaderStatus } from "@googlemaps/js-api-loader";
import { FC, useEffect, useRef, useState } from "react";

interface GoogleMapParams {
  loaderStatus: LoaderStatus;
  coords: google.maps.LatLngLiteral;
  setCoords: (data: google.maps.LatLngLiteral) => void;
  zoom: number;
}

const GoogleMap: FC<GoogleMapParams> = ({ loaderStatus, coords, setCoords, zoom }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [map, setMap] = useState<google.maps.Map>();
  const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement>();

  const onClick = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      setCoords(e.latLng.toJSON());
    }
  };

  useEffect(() => {
    if (loaderStatus === LoaderStatus.SUCCESS && ref.current && !map) {
      const newMap = new window.google.maps.Map(ref.current, {
        disableDefaultUI: true,
        fullscreenControl: true,
        zoomControl: true,
        mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
      });
      newMap.addListener("click", onClick);
      setMap(newMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderStatus, ref, map]);

  useEffect(() => {
    const initMap = async () => {
      const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as google.maps.MarkerLibrary;
      if (map) {
        if (!marker) {
          const markerIcon = document.createElement("img");
          markerIcon.src = "/maps/marker.svg";
          setMarker(
            new AdvancedMarkerElement({
              position: coords,
              map,
              content: markerIcon,
            }),
          );
        } else {
          marker.position = coords;
        }
        map.panTo(coords);
      }
    };
    initMap();
  }, [map, marker, coords]);

  useEffect(() => {
    if (map) {
      map.setZoom(zoom);
    }
  }, [map, zoom]);

  return <div ref={ref} id="map" style={{ width: "100%", height: "100%", borderRadius: "12px" }} />;
};

export default GoogleMap;

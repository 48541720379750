import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Box, Button, Card, CardContent, Link, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FC, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { fetchTariffSummaries } from "src/api/tariffs";
import UserContext from "src/components/UserContext";
import { notifyAxiosError } from "src/components/notifications";
import { Can } from "src/services/permissions/context";

type TarriffTableItem = {
  id: string;
  name: string;
  description: string;
  currency: string;
};

const TariffsTable: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const user = useContext(UserContext);
  const [data, setData] = useState<Array<TarriffTableItem>>([]);

  useEffect(() => {
    fetchTariffSummaries(user.entityFilter)
      .then((response) => {
        setData(
          response.data.map(({ id, name, description, currency }) => ({
            id,
            name,
            description,
            currency,
          })),
        );
      })
      .catch((err) => notifyAxiosError(err, intl));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardContent>
        <Box display="flex" flexDirection="row" flexGrow={1} justifyContent="space-between">
          <Typography variant="h3" align="left" marginBottom={1}>
            <FormattedMessage id="tariff_pageHeader" />
          </Typography>
          <Can I="create" a="Tariff">
            <Button
              variant="contained"
              startIcon={<AddOutlinedIcon fontSize="small" />}
              onClick={() => navigate("/tariffs/form/new")}
            >
              <Typography variant="h6" align="center" marginLeft={1}>
                <FormattedMessage id="tariff_add" />
              </Typography>
            </Button>
          </Can>
        </Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell><FormattedMessage id="id" /></TableCell> */}
                <TableCell>
                  <FormattedMessage id="genericForm_name" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="genericForm_description" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="genericForm_currency" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <Link underline="hover" href={`/tariffs/${row.id}`}>
                      {row.name}
                    </Link>
                  </TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.currency}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default TariffsTable;

import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { DateRange, PickersShortcutsItem, StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getLocationSummaries } from "src/api/locations";
import getRfids from "src/api/rfid";
import { getSessionReport } from "src/api/sessions";
import { getStationSummaries } from "src/api/stations";
import UserContext from "src/components/UserContext";
import Page from "src/components/layout/Page";
import { notifyAxiosError } from "src/components/notifications";
import { ReportFilterValues, initialValues, validationSchema } from "src/pages/Reports/schema";

dayjs.extend(utc);
dayjs.extend(timezone);
const tz = dayjs.tz.guess();

export const downloadCSVFile = (content: string, fileName: string = "download"): void => {
  const dataURI = `data:text/csv;charset=utf-8,\uFEFF ${encodeURIComponent(content)}`;

  const anchor = document.createElement("a");
  anchor.href = dataURI;

  anchor.download = fileName;
  anchor.setAttribute("style", "visibility:hidden");

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

type AutocompleteItem = {
  id: string;
  label: string;
};

const AutocompleteItemEmpty: AutocompleteItem = { id: "", label: "" };

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Last Month",
    getValue: () => {
      const today = dayjs();
      const prevMonth = today.subtract(1, "month");
      return [prevMonth.startOf("month"), prevMonth.endOf("month")];
    },
  },
  {
    label: "Last 30 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(30, "day"), today];
    },
  },
];

const Reports: FC = () => {
  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: "reports_pageHeader" });

  const user = useContext(UserContext);
  const [locations, setLocations] = useState<Array<AutocompleteItem>>([]);
  const [selectedLocation, setSelectedLocation] = useState<AutocompleteItem>(AutocompleteItemEmpty);
  const [locationCheckboxChecked, setLocationCheckboxChecked] = useState(false);
  const [stationCheckboxChecked, setStationCheckboxChecked] = useState(false);
  const [stations, setStations] = useState<Array<AutocompleteItem>>([]);
  const [rfids, setRfids] = useState<Array<AutocompleteItem>>([]);

  useEffect(() => {
    getLocationSummaries(user.entityFilter)
      .then((response) => {
        if (!response.data.length) {
          setLocations([]);
          return;
        }

        setLocations(
          response.data.map(({ id, name }) => ({
            id,
            label: name,
          })),
        );
      })
      .catch((err) => notifyAxiosError(err, intl));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedLocation.id === "") {
      setStations([]);
      return;
    }

    getStationSummaries({
      ...user.entityFilter,
      locationId: selectedLocation.id,
    })
      .then((response) => {
        if (!response.data.length) {
          setStations([]);
          return;
        }

        setStations(
          response.data.map(({ id, ocppStationId }) => ({
            id,
            label: ocppStationId,
          })),
        );
      })
      .catch((err) => notifyAxiosError(err, intl));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  useEffect(() => {
    getRfids(user.entityFilter)
      .then((response) => {
        if (!response.data.content.length) {
          setRfids([]);
          return;
        }

        setRfids(
          response.data.content.map(({ id, alias }) => ({
            id,
            label: alias === "" ? id : `${alias} (${id})`,
          })),
        );
      })
      .catch((err) => notifyAxiosError(err, intl));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (params: ReportFilterValues) => {
    const { filterByLocation, locationId, filterByStation, stationId, filterByRFID, rfid, fromDate, untilDate } =
      params;

    // we have two options ...
    // ... direct browser to the download link (requires JWT to be stored as cookie, or have srv provide single use token)
    // ... download on client side, and then offer it via urlencoded link
    // first one is better for large files, as it doesn't need to be cached on client side first
    // second is better if we want to do any mapping, formatting, i18n on the client side
    // we'll use the second one for now
    // https://stackoverflow.com/questions/55313748/download-file-by-clicking-a-button-in-reactjs

    getSessionReport({
      ...user.entityFilter,
      timezone: tz,
      startTimeFrom: fromDate.toISOString(),
      startTimeTo: untilDate.toISOString(),
      locationId: filterByLocation ? locationId : undefined,
      stationId: filterByStation ? stationId : undefined,
      rfid: filterByRFID ? rfid : undefined,
    })
      .then((response) =>
        downloadCSVFile(response.data, `sessions-${fromDate.format("YYYYMMDD")}-${untilDate.format("YYYYMMDD")}`),
      )
      .catch((err) => notifyAxiosError(err, intl));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Page title={pageTitle} breadcrumbs={[]}>
      <Card>
        <CardContent>
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="column">
              <Typography variant="h3" align="left" marginBottom={3}>
                <FormattedMessage id="reports_pageHeader" />
              </Typography>
              <Typography variant="h5" align="left">
                <FormattedMessage id="reports_filterByLabel" />
              </Typography>
            </Box>

            <form onSubmit={formik.handleSubmit}>
              <Box>
                <StaticDateRangePicker
                  calendars={2}
                  slotProps={{
                    shortcuts: {
                      items: shortcutsItems,
                    },
                    actionBar: {
                      actions: [],
                    },
                  }}
                  value={[formik.values.fromDate, formik.values.untilDate]}
                  onChange={(range) => {
                    formik.setFieldValue("fromDate", range[0]?.startOf("day") ?? dayjs());
                    formik.setFieldValue("untilDate", range[1]?.endOf("day") ?? range[0]?.endOf("day") ?? dayjs());
                  }}
                />
              </Box>
              <Box display="flex" flexDirection="column" width={800}>
                {[
                  {
                    label: "location_label",
                    cb: { id: "filterByLocation", value: formik.values.filterByLocation },
                    val: {
                      id: "location_label",
                      options: locations,
                      value: formik.values.locationId,
                      touched: formik.touched.locationId,
                      error: formik.errors.locationId,
                      onChangeOverride: (_e: any, value: AutocompleteItem | null) => {
                        setSelectedLocation(value ?? AutocompleteItemEmpty);
                        formik.setFieldValue("locationId", value?.id);
                      },
                    },
                  },
                  {
                    label: "station_label",
                    cb: { id: "filterByStation", value: formik.values.filterByStation },
                    val: {
                      id: "station_label",
                      options: stations,
                      value: formik.values.stationId,
                      touched: formik.touched.stationId,
                      error: formik.errors.stationId,
                    },
                  },
                  {
                    label: "rfid_label",
                    cb: { id: "filterByRFID", value: formik.values.filterByRFID },
                    val: {
                      id: "rfid_label",
                      options: rfids,
                      value: formik.values.rfid,
                      touched: formik.touched.rfid,
                      error: formik.errors.rfid,
                    },
                  },
                ].map((field) => (
                  <Box key={field.label} display="flex" flexDirection="column">
                    <FormControlLabel
                      label={intl.formatMessage({ id: field.label })}
                      control={
                        <Checkbox
                          id={field.cb.id}
                          name={field.cb.id}
                          value={field.cb.value}
                          onChange={(e) => {
                            formik.handleChange(e);
                            if (field.cb.id === "filterByLocation") {
                              setLocationCheckboxChecked(e.target.checked);
                              setStationCheckboxChecked(stationCheckboxChecked && e.target.checked);
                              if (!e.target.checked) {
                                formik.setFieldValue("filterByStation", false);
                              }
                            } else if (field.cb.id === "filterByStation") {
                              setStationCheckboxChecked(e.target.checked);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          checked={
                            field.cb.id === "filterByStation"
                              ? stationCheckboxChecked && locationCheckboxChecked
                              : undefined
                          }
                          disabled={field.cb.id === "filterByStation" && !locationCheckboxChecked}
                        />
                      }
                    />
                    <Autocomplete
                      disablePortal
                      options={field.val.options}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      disabled={!field.cb.value}
                      id={field.val.id}
                      onChange={
                        field.val.onChangeOverride ?? ((_e, value) => formik.setFieldValue(field.val.id, value?.id))
                      }
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          // fullWidth
                          sx={{ marginBottom: 3 }}
                          label={intl.formatMessage({ id: field.val.id })}
                          error={field.val.touched && Boolean(field.val.error)}
                          helperText={
                            field.val.touched && field.val.error && intl.formatMessage({ id: field.val.error })
                          }
                        />
                      )}
                    />
                  </Box>
                ))}
              </Box>
              <Button
                // fullWidth
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  marginTop: "60px",
                }}
              >
                <FormattedMessage id="reportsAction_export" />
              </Button>
            </form>
          </Box>
        </CardContent>
      </Card>
    </Page>
  );
};

export default Reports;

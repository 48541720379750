import { Box, Button, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import PasswordField from "src/components/forms/PasswordField";
import PublicPage from "src/components/layout/PublicPage";
import { notifyError } from "src/components/notifications";
import {
  UserPasswordResetFormValues,
  UserPasswordResetInitialValues,
  resetPasswordValidation,
} from "src/pages/users/schema";
import { logOut, resetPassword } from "src/services/auth";

const UserPasswordReset: FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const pageTitle = intl.formatMessage({ id: "userAction_resetPassword" });

  const onSubmit = async (params: UserPasswordResetFormValues) => {
    const result = await resetPassword(params.password);

    if (result.errId) {
      notifyError(result.errId, intl);
    } else if (!result.changePasswordRequired) {
      navigate("/");
    }
  };

  const handleLogOut = async () => {
    logOut();
  };

  return (
    <PublicPage title={pageTitle}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" margin="auto">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="h2" align="center" marginTop="10px" marginBottom="30px">
            <FormattedMessage id="userAction_resetPassword" />
          </Typography>
        </Box>

        <Formik
          initialValues={UserPasswordResetInitialValues}
          validationSchema={resetPasswordValidation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Grid container alignItems="stretch" direction="column" rowSpacing={7}>
                <Grid item>
                  <PasswordField
                    id="password"
                    name="password"
                    label="genericForm_passwordLabel"
                    value={formik.values.password}
                    touched={formik.touched.password}
                    error={formik.errors.password}
                  />
                  <PasswordField
                    id="confirm-password"
                    name="confirmPassword"
                    label="genericForm_confirmPasswordLabel"
                    value={formik.values.confirmPassword}
                    touched={formik.touched.confirmPassword}
                    error={formik.errors.confirmPassword}
                  />
                </Grid>
                <Grid item>
                  <Grid container alignItems="stretch" direction="column" rowSpacing={3}>
                    <Grid item>
                      <Button fullWidth variant="contained" color="primary" type="submit">
                        <FormattedMessage id="generic_resetLabel" />
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button onClick={handleLogOut} fullWidth variant="contained" color="primary">
                        <FormattedMessage id="userAction_logout" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </PublicPage>
  );
};

export default UserPasswordReset;

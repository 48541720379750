import { DisplayText } from "src/api/common/interfaces";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

export interface LocationFormValues {
  locationId: string;
  name: string;
  countryCode: string;
  state?: string;
  city: string;
  address: string;
  postalCode: string;
  contactName?: string;
  contactNumber?: string;
  contactEmail?: string;
  activeTariffIdAc?: string;
  activeTariffIdDc?: string;
  activeTariffNameAc?: string;
  activeTariffNameDc?: string;
  emi3Name: string;
  ocpiAvailability: string;
  powerConnectionId: string;
  powerConnectionLoadManagement: string;
  powerConnectionMaxWattage: number;
  directions?: Array<DisplayText>;
  enableAutoRestart: boolean;
}

export enum OcpiAvailabilityType {
  Unpublished = "UNPUBLISHED",
  Unlisted = "UNLISTED",
  Public = "PUBLIC",
}

export enum LoadManagementType {
  Disabled = "DISABLED",
  EvenDistribution = "EVEN_DISTRIBUTION",
}

export enum Iso639LanguageCode {
  English = "en",
  Thai = "th",
}

export const initialValues = (): LocationFormValues => ({
  locationId: uuidv4(),
  name: "",
  countryCode: "SG",
  city: "",
  address: "",
  postalCode: "",
  emi3Name: "",
  ocpiAvailability: OcpiAvailabilityType.Unpublished,
  powerConnectionId: uuidv4(),
  powerConnectionLoadManagement: LoadManagementType.Disabled,
  powerConnectionMaxWattage: 0,
  directions: [],
  enableAutoRestart: false,
});

// Related schema
// https://github.com/ocpi/ocpi/blob/master/mod_locations.asciidoc
const fields = {
  name: Yup.string().required("genericForm_missingField").max(255),
  emi3Name: Yup.string()
    .required("genericForm_missingField")
    .max(12) // backend requirement, not found in linked doc
    .matches(/^[A-Z0-9]+$/),
  countryCode: Yup.string().required("genericForm_missingField").max(2),
  state: Yup.string().max(20),
  city: Yup.string().required("genericForm_missingField").max(45),
  address: Yup.string().required("genericForm_missingField").max(45),
  postalCode: Yup.string().required("genericForm_missingField").max(10),
  ocpiAvailability: Yup.string().required("genericForm_missingField"),
  powerConnectionMaxWattage: Yup.number().typeError("genericForm_missingField"),
  directions: Yup.array().of(
    Yup.object().shape({
      language: Yup.string().required("genericForm_missingField"),
      text: Yup.string().required("genericForm_missingField"),
    }),
  ),
  // latitude and longitude are always non null as they're set by a googlemaps widget, no assertion needed
};

export const validationSchema = Yup.object().shape({
  ...fields,
});

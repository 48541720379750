import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

const StationUptimeDataUnavailable = () => (
  <Typography variant="h4" sx={{ textAlign: "center", width: "100%" }}>
    <FormattedMessage id="station_uptimeDataUnavailable" />
  </Typography>
);

export default StationUptimeDataUnavailable;

import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

const EntityEditForm: FC<{
  title: string;
  onSubmit: () => void;
  onClose: () => void;
  children: ReactNode;
}> = ({ title, onSubmit, onClose, children }) => (
  <Card>
    <CardContent>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column">
          <Typography variant="h3" align="left" marginBottom={3}>
            <FormattedMessage id={title} />
          </Typography>
        </Box>

        <form onSubmit={onSubmit}>
          <Grid container spacing={3}>
            {children}
          </Grid>

          <Box
            sx={{
              marginTop: 6,
            }}
          >
            <Button variant="contained" color="primary" type="submit">
              <FormattedMessage id="generic_submitLabel" />
            </Button>
            <Button
              variant="text"
              color="primary"
              sx={{
                marginLeft: 8,
              }}
              onClick={onClose}
            >
              <FormattedMessage id="generic_cancelLabel" />
            </Button>
          </Box>
        </form>
      </Box>
    </CardContent>
  </Card>
);

export default EntityEditForm;

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Box, Button, Card, CardContent, Grid, List, ListItem, Table, Typography } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { getTariff } from "src/api/tariffs";
import { TariffDetailsResponse, TaxDefinition } from "src/api/tariffs/interfaces";
import Page from "src/components/layout/Page";
import { notifyAxiosError } from "src/components/notifications";
import { formatTimePeriod } from "src/i18n/timePeriod";
import { Can } from "src/services/permissions/context";

const TariffDetails: FC = () => {
  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: "tariffDetails_pageHeader" });

  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState<TariffDetailsResponse | undefined>(undefined);

  useEffect(() => {
    if (!id) {
      navigate("/tariffs");
      return;
    }

    getTariff(id)
      .then((response) => setData(response.data))
      .catch((err) => notifyAxiosError(err, intl));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      title={pageTitle}
      breadcrumbs={[{ title: "tariff_pageHeader", link: "/tariffs" }, { title: "tariffDetails_pageHeader" }]}
    >
      <Box sx={{ width: "100%" }}>
        <Card>
          <CardContent>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
              <Box>
                <Typography variant="h3" align="left" marginBottom={3}>
                  <FormattedMessage id="tariffDetails_pageHeader" />
                </Typography>
              </Box>

              <Box>
                <Can I="update" a="Tariff">
                  <Button
                    onClick={() => navigate(`/tariffs/form/edit/${data?.id}`)}
                    sx={{
                      marginLeft: "auto", // Pushes the button to the right
                    }}
                  >
                    <SettingsOutlinedIcon fontSize="large" />
                  </Button>
                </Can>
              </Box>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableBody sx={{ td: { borderBottom: "none", verticalAlign: "top" } }}>
                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            <FormattedMessage id="genericForm_name" />:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" color="primary.main">
                            {data?.name || ""}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            <FormattedMessage id="genericForm_description" />:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" color="primary.main">
                            {data?.description || ""}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            <FormattedMessage id="genericForm_currency" />:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" color="primary.main">
                            {data?.currency || ""}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      {data?.taxDefinition !== TaxDefinition.VAT_UNDEFINED && (
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6">
                              <FormattedMessage id="tariff_vatPercentageLabel" />:
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" color="primary.main">
                              {`${data?.taxPercentage}%`}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {data?.taxDefinition !== TaxDefinition.VAT_UNDEFINED && (
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6">
                              <FormattedMessage id="tariff_vatIncludedLabel" />:
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" color="primary.main">
                              <FormattedMessage
                                id={`${
                                  data?.taxDefinition === TaxDefinition.VAT_INCLUDED
                                    ? "genericForm_yes"
                                    : "genericForm_no"
                                }`}
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {data?.sessionFee !== 0 && (
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6">
                              <FormattedMessage id="tariff_activationPriceLabel" />:
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" color="primary.main">
                              {data?.sessionFee !== undefined && (
                                <FormattedNumber value={data.sessionFee} style="currency" currency={data.currency} />
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {data?.kwhFee !== 0 && (
                        <>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h5">
                                <FormattedMessage id="tariffType_kwh" />
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <Typography variant="h6" marginTop={1}>
                                <FormattedMessage id="tariff_label" />:
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography marginTop={1} variant="body2" color="primary.main">
                                {data?.kwhFee && (
                                  <>
                                    <FormattedNumber value={data.kwhFee} style="currency" currency={data.currency} />
                                    &nbsp;per kWh
                                  </>
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </>
                      )}

                      {data?.chargingHourFee !== 0 && data?.chargingHourStepSize !== 0 && (
                        <>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h5">
                                <FormattedMessage id="tariffType_time" />
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <Typography variant="h6" marginTop={1}>
                                <FormattedMessage id="tariff_label" />:
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography marginTop={1} variant="body2" color="primary.main">
                                {data?.chargingHourFee && (
                                  <>
                                    <FormattedNumber
                                      value={data.chargingHourFee}
                                      style="currency"
                                      currency={data.currency}
                                    />
                                    &nbsp;per hour, charged in blocks of {data.chargingHourStepSize} mins
                                  </>
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </>
                      )}

                      {data?.parkingHourFee !== 0 && data?.parkingHourStepSize !== 0 && (
                        <>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h5">
                                <FormattedMessage id="tariff_idlingFeeLabel" />
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <Typography variant="h6" marginTop={1}>
                                <FormattedMessage id="tariff_label" />:
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography marginTop={1} variant="body2" color="primary.main">
                                {data?.parkingHourFee && (
                                  <>
                                    <FormattedNumber
                                      value={data.parkingHourFee}
                                      style="currency"
                                      currency={data.currency}
                                    />
                                    &nbsp;per hour, charged in blocks of {data.parkingHourStepSize} mins
                                  </>
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              <Typography variant="h6">
                                <FormattedMessage id="tariff_gracePeriodLabel" />:
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" color="primary.main">
                                {`${data?.parkingGracePeriodMin} mins`}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          {data?.parkingHourFeeLimit !== 0 && (
                            <TableRow>
                              <TableCell>
                                <Typography variant="h6">
                                  <FormattedMessage id="tariff_idlingFeeMaxPriceLabel" />:
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2" color="primary.main">
                                  {data?.parkingHourFeeLimit && (
                                    <FormattedNumber
                                      value={data.parkingHourFeeLimit}
                                      style="currency"
                                      currency={data.currency}
                                    />
                                  )}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}

                          {data?.parkingExceptionPeriods && data.parkingExceptionPeriods.length > 0 && (
                            <TableRow>
                              <TableCell>
                                <Typography variant="h6">
                                  <FormattedMessage id="tariff_feeExemptionPeriodLabel" />:
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <List disablePadding sx={{ "li:first-child": { paddingTop: 0 } }}>
                                  {data.parkingExceptionPeriods.map((item) => (
                                    <ListItem key={item.from.toString() + item.to.toString()} sx={{ paddingLeft: 0 }}>
                                      <Typography variant="body2" color="primary.main">
                                        {formatTimePeriod(intl, item.from, item.to)}
                                      </Typography>
                                    </ListItem>
                                  ))}
                                </List>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Page>
  );
};

export default TariffDetails;

import { Box, Button } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

export type TimeRangeOption = "1D" | "1W" | "1M" | "3M" | "1Y" | "ALL";

export const allTimeRangeOptions: Array<TimeRangeOption> = ["1D", "1W", "1M", "3M", "1Y", "ALL"];

export const singlesTimeRangeOptions: Array<TimeRangeOption> = ["1D", "1W", "1M", "1Y"];

export const shortTimeRangeOptions: Array<TimeRangeOption> = ["1D", "1W", "1M"];

export interface TimeRangePickerProps {
  options: Array<TimeRangeOption>;
  selected: number;
  onChange: (newValue: number) => void;
}

const TimeRangePicker: FC<TimeRangePickerProps> = ({ options, selected, onChange }) => (
  <Box display="flex" flexDirection="row" flexGrow={1} justifyContent="flex-end">
    {options.map((item, index) => (
      <Button key={item} variant={selected === index ? "contained" : "text"} onClick={() => onChange(index)}>
        <FormattedMessage id={`timeRange_${item}`} />
      </Button>
    ))}
  </Box>
);

export default TimeRangePicker;

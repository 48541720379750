import { useAbility } from "@casl/react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { postStationChangeAvailability, postStationReset, postStationStart } from "src/api/stations";
import { ChangeAvailResponseCode, StationDetailsResponse, StationDetailsStatus } from "src/api/stations/interface";
import DropDownMenu from "src/components/DropDownMenu";
import { notifyAxiosError, notifyError, notifyInfo } from "src/components/notifications";
import { AbilityContext, Can } from "src/services/permissions/context";
import EvseCard from "./EvseCard/EvseCard";

export interface EvseInfoProps {
  station: StationDetailsResponse;
}

const EvseInfo: FC<EvseInfoProps> = ({ station }) => {
  const intl = useIntl();
  const ability = useAbility(AbilityContext);

  const changeAvailability = (toAvailable: boolean) => {
    postStationChangeAvailability({
      id: station.id,
      toAvailable,
    })
      .then((resp) => {
        if (resp.data.code === ChangeAvailResponseCode.scheduled) {
          notifyInfo("stationResponse_changeAvailabilityScheduled", intl);
        } else if (resp.data.code === ChangeAvailResponseCode.accepted) {
          notifyInfo("stationResponse_changeAvailabilityAccepted", intl);
        } else {
          notifyError("stationChangeAvailabilityError_unknownResponse", intl);
        }
      })
      .catch((err) => notifyAxiosError(err, intl));
  };

  const resetStation = (resetType: "soft" | "hard") => {
    postStationReset({
      id: station.id,
      resetType,
    })
      .then(() => {
        notifyInfo("stationAction_resetStation", intl);
      })
      .catch((err) => notifyAxiosError(err, intl));
  };

  const startStation = () => {
    postStationStart(station.id)
      .then(() => {
        notifyInfo("stationAction_remoteStartStation", intl);
      })
      .catch((err) => notifyAxiosError(err, intl));
  };

  const setUnavailable = () => changeAvailability(false);
  const setAvailable = () => changeAvailability(true);
  const softReset = () => resetStation("soft");
  const hardReset = () => resetStation("hard");

  return (
    <Card>
      <CardContent>
        <Grid container direction="column" spacing={2}>
          {/* Label + action button container */}
          <Grid container item justifyContent="space-between">
            <Grid item>
              <Typography variant="h3">
                <FormattedMessage id="station_label" />
              </Typography>
            </Grid>

            <Grid item>
              <Can I="control" a="Station">
                <DropDownMenu
                  title="station_actionsLabel"
                  items={[
                    station.status === StationDetailsStatus.inoperative
                      ? { title: "stationAction_setAvailable", onClick: setAvailable }
                      : { title: "stationAction_setUnavailable", onClick: setUnavailable },
                    { title: "stationAction_resetSoft", onClick: softReset },
                    { title: "stationAction_resetHard", onClick: hardReset },
                    ...(ability.can("debug", "Station")
                      ? [{ title: "stationDebug_remoteStart", onClick: startStation }]
                      : []),
                  ]}
                />
              </Can>
            </Grid>
          </Grid>

          {/* EVSE cards */}
          <Grid container item direction="row" spacing={2}>
            {station.evses.map((evse) => (
              <Grid item key={`evse-card-${station.id}-${evse.id}`} xs={12} md={6}>
                <EvseCard stationId={station.id} evse={evse} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EvseInfo;

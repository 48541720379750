import { Box, Container, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Page from "src/components/layout/Page";

const NotFound = () => (
  <Page title="httpResponse_pageNotFound" breadcrumbs={[]}>
    <Box height="100vh">
      <Container maxWidth="md">
        <Typography align="center" color="textPrimary" variant="h2">
          <FormattedMessage id="httpResponse_404" />
        </Typography>
        <Typography align="center" color="textPrimary" variant="subtitle2">
          <FormattedMessage id="httpResponse_pageNotFoundHelperText" />
        </Typography>
      </Container>
    </Box>
  </Page>
);

export default NotFound;
